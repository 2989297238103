import styled from '@emotion/styled'
import { Button } from 'driverama-core/components/button/Button'
import { Flex } from 'driverama-core/components/Flex'

export const SWrapper = styled(Flex)`
  text-align: center;
  width: 80%;
  margin: 0 auto;
`

export const SButton = styled(Button)`
  width: 100%;
`
