import styled from '@emotion/styled'
import { Button } from 'driverama-core/components/button/Button'
import { FormControl } from 'driverama-core/components/formControl/FormControl'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, radius } from 'driverama-core/styles/variables'

export const SCard = styled.div<{ lightTheme: boolean }>`
  padding: ${size(6)};
  background-color: ${({ lightTheme }) =>
    color(lightTheme ? 'white' : 'night-l-800')};
  border-radius: ${radius('corner')};
  flex: 1;
  position: relative;

  box-shadow: ${({ lightTheme }) =>
    lightTheme ? '0px 10px 30px 2px rgba(0, 0, 0, 0.1)' : 'none'};

  @media ${media.lte('MD')} {
    max-width: 100%;
  }

  @media ${media.lte('mobile')} {
    padding: ${size(5)} ${size(4)};
  }
`

export const SInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${size(2)};
`

export const SHalfFormControl = styled(FormControl)`
  flex: 1;
`

export const SButton = styled(Button)`
  width: 100%;
`
