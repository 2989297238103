import { useCloseModalContext } from 'driverama-core/components/modal/Modal.utils'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import {
  TextBody,
  TextHeader,
  TextSubhead
} from 'driverama-core/components/text/Text'
import { useTranslation } from 'react-i18next'
import { SButton, SWrapper } from './FinancingInquirySuccess.styled'
import { areWorkingHours, isLocalWeekend } from 'utils/datetime'
import { financingWorkingHours } from 'constants/financing'

export function FinancingInquirySuccess() {
  const { t } = useTranslation(['sell', 'core'])

  const closeModal = useCloseModalContext()

  const renderDescription = () => {
    if (areWorkingHours(financingWorkingHours)) {
      return t('core:financing_inquiry_success_description_working_hours')
    }

    if (isLocalWeekend()) {
      return t('core:financing_inquiry_success_description_weekend')
    }

    return t('core:financing_inquiry_success_description_not_working_hours')
  }

  return (
    <>
      <SWrapper variant="column" justify="center">
        <TextSubhead variant="normal">{t('core:hurray')}</TextSubhead>
        <TextHeader variant={['h5', 'h3']}>
          {t('core:financing_inquiry_success_header')}
        </TextHeader>

        <Spacer size={4} />

        <TextBody>{renderDescription()}</TextBody>

        <Spacer size={6} />
      </SWrapper>
      <SButton variant="primary" onClick={closeModal}>
        {t('core:ok')}
      </SButton>
    </>
  )
}
