import { OrderLoanRequestBody } from 'driverama-core/api/driverama/orders/orderLoanRequest'
import { SCard } from './FinancingInquiry.styled'
import { FinancingInquiryForm } from './FinancingInquiryForm'

interface Props {
  carStockNo?: string
  loanRequestSource?: OrderLoanRequestBody['loanRequestSource']
  loanRequestType?: OrderLoanRequestBody['loanRequestType']
  lightTheme?: boolean
}

export function FinancingInquiryFormCard({
  lightTheme = false,
  ...other
}: Props) {
  return (
    <>
      <SCard lightTheme={lightTheme}>
        <FinancingInquiryForm {...other} />
      </SCard>
    </>
  )
}
