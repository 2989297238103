import { operations } from 'driverama-core/api/driverama/generated/orders'
import { URLS } from 'driverama-core/constants/api'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { apiAuthFetcher } from 'driverama-core/utils/fetcher'
import { useMutation, UseMutationOptions } from 'react-query'

type MutationResponse =
  | operations['requestFinancingCall']['responses']['204']['content']['application/com.driverama-v1+json']
  | undefined

type MutationBody = operations['requestFinancingCall']['requestBody']['content']['application/json']

export async function sendLoanRequest(body: MutationBody) {
  const res = await apiAuthFetcher<MutationResponse>(URLS.orderLoanRequest, {
    method: HTTPMethod.POST,
    body
  })

  return res.json
}

export function useSendLoanRequestMutation(
  opts?: UseMutationOptions<MutationResponse, unknown, MutationBody>
) {
  return useMutation<MutationResponse, unknown, MutationBody>(
    async body => await sendLoanRequest(body),
    opts
  )
}

export type OrderLoanRequestBody = MutationBody

export type RequestLoanQuery = ReturnType<typeof useSendLoanRequestMutation>
