import { useTranslation } from 'react-i18next'
import { TextBody, TextHeader } from 'driverama-core/components/text/Text'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { css } from '@emotion/react'
import { color } from 'driverama-core/styles/variables'

export function FinancingInquiryHeader() {
  const { t } = useTranslation(['sell', 'core'])

  return (
    <>
      <TextHeader
        variant={['h4', 'h3']}
        css={css`
          color: ${color('night-l-100')};
        `}
      >
        {t('sell:car_detail_financing_inquiry')}
      </TextHeader>

      <Spacer size={4} />

      <TextBody
        css={css`
          color: ${color('night-l-100')};
        `}
      >
        {t('sell:car_detail_financing_inquiry_description')}
      </TextBody>

      <Spacer size={8} />
    </>
  )
}
