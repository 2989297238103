import {
  SButton,
  SHalfFormControl,
  SInputsWrapper
} from './FinancingInquiry.styled'
import { useTranslation } from 'react-i18next'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import IconGlobe from 'images/icons/IconGlobeThin.svg'
import IconHome from 'images/icons/IconHome.svg'
import IconUser from 'images/icons/IconUserThin.svg'
import {
  InquiryFormValues,
  useInquiryFormValidationSchema
} from './FinancingInquiry.utils'
import { ToggleText } from 'driverama-core/components/toggleText/ToggleText'
import { FormControl } from 'driverama-core/components/formControl/FormControl'
import { getFormError, isNil } from 'driverama-core/utils/types'
import { PhoneInput } from 'driverama-core/components/phoneInput/PhoneInput'
import { css } from '@emotion/react'
import { size } from 'driverama-core/styles/spacing'
import { media } from 'driverama-core/styles/media'
import { Flex } from 'driverama-core/components/Flex'
import { color } from 'driverama-core/styles/variables'
import { useModal } from 'driverama-core/components/modal/Modal'
import { FinancingInquirySuccess } from './success/FinancingInquirySuccess'
import { toast } from 'driverama-core/components/toast/Toast'
import {
  OrderLoanRequestBody,
  useSendLoanRequestMutation
} from 'driverama-core/api/driverama/orders/orderLoanRequest'
import { parsePhoneNumber } from 'react-phone-number-input'
import { FinancingInquiryHeader } from 'sections/financing/inquiry/FinancingInquiryHeader'
import { logGAEvent } from 'utils/analytics/events'
import { useCloseModalContextOptional } from 'driverama-core/components/modal/Modal.utils'
import { SelectUncontrolled } from 'driverama-core/components/select/SelectUncontrolled'
import { useMedia } from 'react-use'
import { useEffect, useState } from 'react'
import { logGtmFormSubmit } from 'utils/gtm/gtmEvents'
import { normalizePhonePrefix } from 'driverama-core/utils/strings'

interface Props {
  carStockNo?: string
  loanRequestSource?: OrderLoanRequestBody['loanRequestSource']
  loanRequestType?: OrderLoanRequestBody['loanRequestType']
  onSubmit?: () => void
  isModal?: boolean
}

export function FinancingInquiryForm({
  carStockNo,
  loanRequestSource,
  loanRequestType,
  onSubmit,
  isModal = false
}: Props) {
  const { t } = useTranslation(['sell', 'core'])
  const isMobile = useMedia(media.lte('mobile'))
  const [userHasInteracted, setUserHasInteracted] = useState(false)

  const closeModal = useCloseModalContextOptional(isModal)

  const showHeader = !carStockNo
  const lightTheme = !carStockNo

  const { schema, errorMap } = useInquiryFormValidationSchema()
  const form = useForm<InquiryFormValues>({
    mode: 'all',
    resolver: zodResolver(schema, { errorMap }),
    defaultValues: {
      customerType: 'PERSON'
    }
  })

  const { mutateAsync: sendLoanRequest } = useSendLoanRequestMutation()

  const [modal, openModal] = useModal(() => <FinancingInquirySuccess />, {
    closeLabel: t('core:ok')
  })

  const onFormSubmit = async ({
    phoneNumber,
    email,
    ...rest
  }: InquiryFormValues) => {
    const parsedPhone = parsePhoneNumber(phoneNumber)
    if (!parsedPhone) {
      throw new Error(
        'Could not parse deputy phone number. This should never happen.'
      )
    }

    const { firstname, surname } = rest

    logGtmFormSubmit('financing_request', {
      name: firstname,
      surname,
      email: email || '',
      phone: `${normalizePhonePrefix(parsedPhone.countryCallingCode)} ${
        parsedPhone.nationalNumber
      }`
    })

    try {
      await sendLoanRequest({
        carStockNo: carStockNo || null,
        phoneNumber: parsedPhone.nationalNumber,
        phonePrefix: normalizePhonePrefix(parsedPhone.countryCallingCode),
        email: email || undefined,
        loanRequestSource,
        loanRequestType,
        ...rest
      })

      closeModal?.()
      if (!isModal) {
        openModal()
        form.reset()
      }
      onSubmit?.()
    } catch (error) {
      toast({ type: 'error', content: t('core:error_common'), error })
    }
  }

  const getFormLocation = () => {
    if (loanRequestSource === 'HOME_PAGE') {
      return 'landing_page'
    }
    if (
      loanRequestSource === 'LANDING_PAGE' &&
      (isNil(loanRequestType) || loanRequestType === 'NOT_SPECIFIED')
    ) {
      return 'financing_page'
    }
    if (loanRequestType === 'LINEAR') {
      return 'linear_loan'
    }
    if (loanRequestType === 'BALLOON') {
      return 'balloon_loan'
    }

    return undefined
  }

  useEffect(() => {
    if (form.formState.isDirty && !userHasInteracted) {
      setUserHasInteracted(true)
      logGAEvent('financing_form_interaction', {
        form_location: getFormLocation()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty])

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onFormSubmit)}>
          {!showHeader && <FinancingInquiryHeader />}

          <Controller
            control={form.control}
            name="customerType"
            render={({ field: { onChange, value } }) => {
              return isMobile ? (
                <SelectUncontrolled
                  name="customerType"
                  onInnerChange={onChange}
                  value={value}
                  variant="rounded"
                  options={[
                    {
                      label: t('core:financing_inquiry_private_person'),
                      value: 'PERSON'
                    },
                    {
                      label: t('core:financing_inquiry_self_employed'),
                      value: 'SELF_EMPLOYED'
                    },
                    {
                      label: t('core:financing_inquiry_company'),
                      value: 'COMPANY'
                    }
                  ]}
                  isRequired
                />
              ) : (
                <ToggleText<InquiryFormValues['customerType']>
                  value={value}
                  onChange={onChange}
                  options={[
                    {
                      label: t('core:financing_inquiry_private_person'),
                      value: 'PERSON',
                      icon: IconUser
                    },
                    {
                      label: t('core:financing_inquiry_self_employed'),
                      value: 'SELF_EMPLOYED',
                      icon: IconHome
                    },
                    {
                      label: t('core:financing_inquiry_company'),
                      value: 'COMPANY',
                      icon: IconGlobe
                    }
                  ]}
                  classes={{
                    container: css`
                      background-color: ${color(
                        lightTheme ? 'night-l-800' : 'night-l-675'
                      )};
                    `
                  }}
                />
              )
            }}
          />

          <Spacer size={6} />

          <SInputsWrapper>
            <Flex variant={['column', 'row', 'row']} gap={2}>
              <SHalfFormControl
                label={t('core:first_name')}
                error={getFormError({
                  form,
                  name: `firstname`
                })}
                isBorderless={!lightTheme}
              >
                <input {...form.register(`firstname`)} />
              </SHalfFormControl>

              <SHalfFormControl
                label={t('core:last_name')}
                error={getFormError({
                  form,
                  name: `surname`
                })}
                isBorderless={!lightTheme}
              >
                <input {...form.register(`surname`)} />
              </SHalfFormControl>
            </Flex>

            <div>
              <Controller
                control={form.control}
                name={`phoneNumber`}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    inputName="phoneNumber"
                    countryLabel={t('core:country')}
                    phoneLabel={t('core:phone')}
                    value={value}
                    onChange={onChange}
                    isBorderless={!lightTheme}
                    isInModal={isModal}
                    wrapperStyles={css`
                      display: grid;
                      grid-template-columns: 1.5fr 2fr 2fr;
                      gap: ${size(2)};

                      fieldset {
                        margin: 0;
                        width: auto;
                        grid-column-start: 2;
                        grid-column-end: 4;
                      }

                      @media ${media.lte('tablet')} {
                        grid-template-columns: 1fr 2fr;
                      }

                      @media ${media.lte('mobile')} {
                        grid-template-columns: 2fr 3fr;
                      }
                    `}
                    error={getFormError({
                      form,
                      name: `phoneNumber`
                    })}
                  />
                )}
              />
            </div>

            <div>
              <FormControl
                label={t('core:email_optional')}
                error={getFormError({ form, name: `email` })}
                isBorderless={!lightTheme}
              >
                <input type="email" {...form.register(`email`)} />
              </FormControl>
            </div>
          </SInputsWrapper>

          <Spacer size={8} />

          <SButton variant="secondary" type="submit">
            {t('core:financing_inquiry_submit')}
          </SButton>
        </form>
      </FormProvider>
      {modal}
    </>
  )
}
