import { useTranslation } from 'react-i18next'
import { useDefaultZodErrorMap } from 'utils/validations'
import { enum as ZodEnum, infer as zodInfer, object, string } from 'zod'

export function useInquiryFormValidationSchema() {
  const { t } = useTranslation(['core'])
  const schema = object({
    customerType: ZodEnum(['PERSON', 'SELF_EMPLOYED', 'COMPANY']),
    firstname: string().min(1, t('core:validation_required')),
    surname: string().min(1, t('core:validation_required')),
    phoneNumber: string(),
    email: string().optional()
  })

  const defaultErrorMap = useDefaultZodErrorMap()
  return { schema, errorMap: defaultErrorMap }
}

export type InquiryFormValues = zodInfer<
  ReturnType<typeof useInquiryFormValidationSchema>['schema']
>
